<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray-50 pt-24 mx-4 sm:mx-24">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Frequently asked questions
        </h2>


        <dl class="mt-6 space-y-6 divide-y divide-gray-200">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-gray-900">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>


      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

const faqs = [
  {
    question: "What is Poente Technical Academy e-learning?",
    answer:
        "Poente Technical Academy e-learning is a remote training platform offering aircraft maintenance courses complying with applicable requirements and regulations for online completion. All courses are approved by Part-147 Maintenance Training Organization.\n" +
        "\n" +
        "Is your Company EASA Part-147 approved?\n" +
        "Poente Technical Academy is an EASA approved Maintenance Training Organization (MTO), Part-147 under approval reference EASA.147.0154",
  },
  {
    question: "Are your certificates valid worldwide?",
    answer: "All our courses are compliant with applicable syllabus requirements. We have numerous clients worldwide, all of whom accept our certificates. However, it is up to the organization whether to accept training certificates. All syllabus is available within each course description."
  },
  {
    question: "How to get PTA course online?",
    answer: "In order to get a course, please sign up  and visit our e-shop. \n" +
        "Add the selected course to the cart and proceed to payment. \n" +
        "Once your payment is received, the course becomes active in your account and you can start with e-learning."
  },
  {
    question: "How do I sign up?",
    answer: "In order to register, please click on register button in the top right corner of the window, provide your email and preferred password. Once you click Register, you will receive an email notification to verify your profile. When your profile is verified, you can start e-learning. "
  },
  {
    question: "Do I need a Private or a Corporate account?",
    answer: "If you are an individual who would like to do the course on your own or share it to your friend, then you need a Private account.\n" +
        "If you are a manager seeking to get your team trained, you will find a Corporate account more beneficial as it allows you to create an employee list, assign a course to a group of employees, monitor the completion progress and receive certificates for the completed courses, all to be stored in one place.\n" +
        "How do your courses look like?\n" +
        "Most of our courses are instructor-led presentations - you will feel as if attending a classroom. Others are presentations with artificial voice over, or a mix of both human and artificial voice over."
  },
  // More questions...
]

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  setup() {
    return {
      faqs,
    }
  },
}
</script>